import React, { useContext } from "react";
import BoxList from "../../components/BoxList/BoxList";
import Faq from "../../components/Faq/Faq";
import Intro from "../../components/Intro_v2/Intro_v2";
import Meta from "../../components/Meta/Meta";
import Spacer from "../../components/Spacer/Spacer";
import SectionTwoCol from "../../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../../contexts/DataContext";
import { toast } from "react-toastify";
import KPI from "../../components/KPI/KPI";
import useScrollController from "../../hooks/useScrollController";
import MapBoxRetailers from "../../components/MapBoxRetailers/MapBoxRetailers";
import useTrack from "../../hooks/useTrack";
import { Helmet } from "react-helmet";
import useSnipcartEvents from "../../hooks/useSnipcartEvents";
import Hero3BC from "../../components/Hero3BC/Hero3BC";

const Box3Bee = () => {
  const { 
    pageLabels,
    currentLanguageSuffix,
    pageImages,
  } = useContext(DataContext);
  const { scrollToCssSelector } = useScrollController();
  const { trackCustomEvent } = useTrack();
  toast.configure();
  useSnipcartEvents();

  return (
    <>
      <Meta />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context":"https://schema.org",
              "@type": "Service",
              "name": pageLabels.hero.title.replace(/<[^>]*>?/gm, ""),
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": pageLabels.hero.paragraph.replace(/<[^>]*>?/gm, ""),
            },
          ])}
        </script>
      </Helmet>
      <Hero3BC
        title = {pageLabels.hero.title}
        image = {pageLabels.hero.image}
        url={pageLabels.video.url}
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#boxes"
        titleBackground = "transparent"
        buttonVariant="orange"
        noNewsletter={true}
        lang={currentLanguageSuffix}
        allImages = {pageImages}
      />
      <Spacer size={10}/>
      <SectionTwoCol
        id="box3bee"
        title={pageLabels.box3bee.title}
        subtitle1={pageLabels.box3bee.paragraph}
        ulTitle={pageLabels.box3bee.containsTitle}
        ul={pageLabels.box3bee.containsList.map(e => {return { text: e };})}
        images={["/2020/images/box-3bee/box_3bee_all.jpg", "/2020/images/box-3bee/box_3bee_3box.jpg", "/2020/images/app2.png", "/2020/images/phoneapp.png"]}
        topImage
      />
      
      <Spacer size={15}/>
      
      <Intro 
        bigText={pageLabels.boxes.title}
        subtitle = {pageLabels.boxes.subtitle}
        textAlign="center"
      />
      <div id="boxes">
        <BoxList onClick={() => scrollToCssSelector("#map", 50)} />
      </div>
      
      <Spacer size={5}/>
      
      <Intro 
        id="map"
        bigText={pageLabels.map.title}
      />
      <MapBoxRetailers />

      <Spacer size={10}/>

      <KPI showBoxKpi/>

      <Spacer size={10}/>

      <SectionTwoCol
        title={pageLabels.contribute.title}
        subtitle1={pageLabels.contribute.paragraph}
        cta={pageLabels.contribute.button}
        to="https://webforms.pipedrive.com/f/2VpXsFIpXTDLmt9Zhc2cjvxUVjGobsfHYufC5IvzCG7NkCQW8WXYoj4ZfVKQDJStd"
        image="/2020/images/box/luca.png"
        rightImage
        topImage
      />

      <Spacer size={10}/>

      <SectionTwoCol
        title={pageLabels.gift.title}
        subtitle1={pageLabels.gift.paragraph}
        cta={pageLabels.gift.button}
        to="/adotta-alveare"
        image="/2020/images/community/mappaalveari.png"
        topImage
      />
      
      <Spacer size={10}/>

      <SectionTwoCol
        title={pageLabels.company.title}
        subtitle1={pageLabels.company.paragraph}
        cta={pageLabels.company.button}
        to="/box"
        onClickCTA={() => {
          trackCustomEvent({
            category: "BoxAdottatori",
            action: "click",
            label: "Diventa rivenditore",
          });
        }}
        image="/2020/images/beebox.png"
        rightImage
        topImage
      />

      <Spacer size={10}/>

      <Faq
        data={pageLabels.faq.dataFile.data}
      />
    </>
  );
};

export default Box3Bee;
