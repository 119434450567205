import React, { useContext, useState } from "react";
import {
  StyledBoxListScroller,
  StyledBoxListScrollerItems,
  StyledBoxListScrollerItem,
  StyledBoxListScrollerItemButton,
  StyleInfoButton,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DataContext } from "../../contexts/DataContext.jsx";
import useTrack from "../../hooks/useTrack.js";
import { Button } from "@3beehivetech/components";

const BoxList = ({ onClick }) => {
  const { trackCustomEvent } = useTrack();
  const { pageLabels } = useContext(DataContext);
  const boxes = [
    {
      image: "/2020/images/box-3bee/box-3bee-1.png",
      id: "box_3bee_12",
      name: "Box 3Bee Segui le Api",
      price: 11.99,
      available: true,
      features: [
        { text: "Adozione con certificato 3Bee" },
        { text: "Monitoraggio di un alveare e delle sue api per 1 anno" },
        { text: "App 3Bee per seguire la crescita dell'alveare" },
        { text: "Spedizione entro il 5 dicembre" }],
    },
    {
      image: "/2020/images/box-3bee/box-3bee-2.png",
      id: "box_3Bee_24",
      name: "Box 3Bee da 24 e 99 euro",
      price: 24.99,
      available: true,
      features: [
        { text: "Adozione con certificato 3Bee con 500 grammi di miele incluso" },
        { text: "Monitoraggio di un alveare e delle sue api per 1 anno" },
        { text: "App 3Bee per seguire la crescita dell'alveare" },
        { text: "Spedizione in 5 giorni lavorativi" }],
    },
    {
      image: "/2020/images/box-3bee/box-3bee-3.png",
      id: "box_3Bee_32",
      name: "Box 3Bee da 34 e 99 euro",
      price: 34.99,
      available: true,
      features: [
        { text: "Adozione con certificato 3Bee con 1 kg di miele incluso" },
        { text: "Monitoraggio di un alveare e delle sue api per 1 anno" },
        { text: "App 3Bee per seguire la crescita dell'alveare" },
        { text: "Spedizione in 5 giorni lavorativi" }],
    },
    {
      image: "/2020/images/box-3bee/bundle_1.png",
      id: "box_3Bee_bundle_scoprileapi",
      name: "Bundle 4 Box Scopri le api",
      price: 35.97,
      available: true,
      features: [
        { text: "3 x Adozione con certificato (no miele)" },
        { text: "1 x Adozione con certificato (no miele) gratis" },
        { text: "App 3Bee per seguire la crescita dell'alveare" },
        { text: "Spedizione in 5 giorni lavorativi" }],
    },
    {
      image: "/2020/images/box-3bee/bundle_2.png",
      id: "box_3Bee_bundlebirichina",
      name: "Bundle 3 Box Birichine e 2 Box Scopri le Api",
      price: 74.97,
      available: true,
      features: [
        { text: "3 x Adozione con certificato 3Bee con 500 gr di miele incluso" },
        { text: "2 x Adozione con certificato (no miele) gratis" },
        { text: "App 3Bee per seguire la crescita dell'alveare" },
        { text: "Spedizione in 5 giorni lavorativi" }],
    },
  ];

  return (
    <StyledGridRow isFullMobile>
      <StyledBoxListScroller>
        <Scroller>
          <StyledBoxListScrollerItems>
            {boxes.map((box, index) => (
              <BoxListScrollerItem
                key={index}
                box={box}
                onClick={() => {
                  trackCustomEvent({
                    category: "BoxAdottatori",
                    action: "click",
                    label: `Box3Bee ${index + 1}`,
                  });
                  onClick();
                }}
              />
            ))}
          </StyledBoxListScrollerItems>
        </Scroller>
      </StyledBoxListScroller>
      <div style="display: flex; justify-content: center; margin-top: 90px;">
        <Button variant="green" onClick={onClick}>
          {pageLabels.boxes.button}
          {"  "}
        </Button>
      </div>
    </StyledGridRow>
  );
};

const BoxListScrollerItem = ({ box }) => {
  const { pageImages } = useContext(DataContext);
  const [info, setInfo] = useState(false);
  const imageData = pageImages.find((e) => e.path === box.image);

  return (
    <StyledBoxListScrollerItem>
      <div style="overflow: hidden; border-radius: 10px;">
        <div style={info ? "filter: blur(25px);" : ""}>
          <GatsbyImage
            image={getImage(imageData)}
            alt={imageData?.alt}
          />
        </div>
      </div>
      <StyleInfoButton onClick ={()=> {setInfo(!info);}}> <div style="color: black; position: relative; top: 25%; font-size: 20px; font-family: Inter; font-weight: 300;"> i </div> </StyleInfoButton>
      <div style={info ? "display: block; position: absolute; top: 40px; left: 20px; padding-right: 20px;" : "display: none;"}>
        <ul style="font-size: 20px; line-height: 22px; text-shadow: 1px 1px 5px black">
          {box.features.map((feature, id) => (
            <li style="margin-top: 10px; "key={id}> {feature.text} </li>
          ))}
        </ul>
      </div>
      <StyledBoxListScrollerItemButton>
        <Button
          style="box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.3)"
          className="snipcart-add-item"
          data-item-id={box.id}
          data-item-url="https://www.3bee.com/box/adottatori/"
          data-item-image={box.image}
          data-item-price={box.price}
          data-item-name={box.name}
          data-item-weight="100"
          data-item-description="Verrà spedita a casa tua e potrai regalarla a chi vuoi o usarla per proteggere le api tu stessa. Dopo il riscatto si attiverà un alveare 3Bee, la protezione delle api e la produzione del tuo miele."
        >
          {box.available ? (<div>Acquista Online <br/><div style="font-size: 12px; line-height: 12px;">6-10giorni consegna</div></div>) : (<div>Preordina <div style="font-size: 12px; line-height: 12px;">Consegna entro 5 dicembre</div></div>)}
        </Button>
      </StyledBoxListScrollerItemButton>
    </StyledBoxListScrollerItem>
  );
};

export default BoxList;

/*
          
          */
